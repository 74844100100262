export default {


    initPage(instance){
        instance.$store.dispatch('GetCate');
        instance.$store.dispatch('GetShip');
       
        instance.$store.dispatch('GetBrand');
        instance.$store.dispatch('GetUser');
  
        instance.$store.dispatch('GetConfig');
    }
}