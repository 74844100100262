import HTTPREQUEST from "../http"
 
export const common={


    GetConfigV2(){
        return HTTPREQUEST.post('/common/GetConfigV2',{
        }
        );
    }
}