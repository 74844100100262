import HTTPREQUEST,{ContentType} from "../http";
export const order = {

  /**
   * 获取配送方式列表 (Auth)
   */
  GetShipList() {
    return HTTPREQUEST.post("/Order/GetShipList");
  },
  /**
   * 订单列表 (Auth)
  */
  GetOrderList(limit,page,status) {
    return HTTPREQUEST.post("/Order/GetOrderList",{
      "limit": limit,
      "page": page,
      "status": status
    });
  },
  /**
   * 订单预览
   */
  OrderDetails(id,data){
    return HTTPREQUEST.post("/Order/OrderDetails",{
      "id": id,
      "data": data
    });
  },

  /**
   * 创建订单
   * @param {*} data {
  "areaId": 0,
  "cartIds": "string",
  "couponCode": "string",
  "memo": "string",
  "point": 0,
  "receiptType": 0,
  "source": 0,
  "taxCode": "string",
  "taxName": "string",
  "taxType": 0,
  "ushipId": 0,
  "storeId": 0,
  "orderType": 0,
  "ladingName": "string",
  "ladingMobile": "string",
  "objectId": 0,
  "teamId": 0,
  "scene": 0
}
   */
  CreateOrder(data){
      return HTTPREQUEST.post("/Order/CreateOrder",data);
  },

  CreateOrderDiyJson(data){
    return HTTPREQUEST.post("/Order/CreateOrderDiyJson",data);
  },
  /**
   * 取消订单
   */
  CancelOrder(id,data){
    return HTTPREQUEST.post("/Order/CancelOrder",{
      "id": id,
      "data": data
    });
  },

  /**
  * 取消订单
  */
  DeleteOrder(id,data){
      return HTTPREQUEST.post("/Order/DeleteOrder",{
        "id": id,
        "data": data
      });
  },

  /**
   * 确认签收订单
   */
  OrderConfirm(id,data){
    return HTTPREQUEST.post("/Order/OrderConfirm",{
      "id": id,
      "data": data
    });
  },

  /**
  * 装订
  */
  GetOrderStatusNum(ids,isAfterSale){
    return HTTPREQUEST.post("/Order/GetOrderStatusNum",{
      ids,
      isAfterSale
    });
  },
  /**
   * 修改订单明细
   * @param {*} data 
   * @returns 
   */
  OrderModify(data){
    return HTTPREQUEST.post("/Order/OrderModify",data);
  },
  OrderDiyModify(data){
    return HTTPREQUEST.post("/Order/OrderDiyModify",data);
  },
  /**
   * 完成编辑
   * @param {*} data 
   * @returns 
   */
  OrderFinish(data){
    return HTTPREQUEST.post("/Order/OrderFinish",data);
  },
  DeleteOrderItems(orderId,itemId){
    return HTTPREQUEST.post("/Order/DeleteOrderItems",{
      orderId,itemId
    });
  },
  OrderItemModify(data){
    return HTTPREQUEST.post("/Order/OrderItemModify",data);
  },

  GetOrderAddrByCompanyName(name){
    return HTTPREQUEST.post("/Order/GetOrderAddrByCompanyName",name);
  }
  
  
};
