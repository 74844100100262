import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Element  from 'element-ui'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
 
import enjson from './lang/en.json'
import zhjson from './lang/zh-CN.json'
import VueI18n from 'vue-i18n'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/style.scss'
import './assets/ele.css'
import './assets/tailwind.min-1.9.0.css'
import qdk from './common/qdk';


let defaultLang=qdk.getStorageSync("lang")

defaultLang =defaultLang || 'zh-CN'


Vue.config.productionTip = false

Vue.prototype.$size='mini'
/**
 * ========== Element ==========
 */

 
Vue.config.lang = defaultLang
 

/**
 * ========== I18N ==========
 */

const messages = {
  'en': {     
  
    ...enjson,
    ...enLocale
  },
  'zh-CN': { 
 
    ...zhjson,
    ...zhLocale
  }
}
Vue.use(VueI18n)
// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  locale: defaultLang, // 设置地区
  messages, // 设置地区信息
})

Vue.use(Element, {
  i18n: (key, value) => {
    //console.log(key,value)
    return i18n.t(key, value)
  }
})

/**
 * ========== init ==========
 */

new Vue({
  router,
  store,
  i18n ,
  render: h => h(App)
}).$mount('#app')
