import Vue from 'vue'
import Vuex from 'vuex'
import { Api } from '../../service/api';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    brands:[],
    categories:[],
    currentCate:-1,
    shipTypes:[
      {
        id:1,
        name:'物流快递',
        englishName:"logistics-express"
      },
      //{
      //  id:2,
      //  name:'同城配送',

      //},
      {
        id:3,
        name:'门店自提',
        englishName:"store-pickup"
        
      }

    ],
    user:{},
    config:{},
    langs:[
      {
         value:'zh-CN',
         label:'中文'
     },
      {
         value:'en',
         label:'English'
     }
    ]
  },
  getters: {
  },
  mutations: {
    setCate(state,p){
      state.categories=p;
    },
    setShip(state,p){
      state.shipTypes=p;
    },
    setBrand(state,p){
      state.brands=p;
    },
    setUser(state,p){
      state.user=p;
    },
    setConfig(state,p){
      state.config=p;
    }
  },
  actions: {
    /**
     * 加载分类
     * @param {*} context 
     */
    GetCate(context){
      Api.good.GetAllCategories().then(res=>{
          if (res && res.code==0){
              context.commit('setCate',res.data);
          }                
      });
    },
    /**
     * 获取物流
     * @param {*} context 
     */
    GetShip(context){
      /*
      Api.order.GetShipList().then(res=>{
          if (res && res.code==0){
            context.commit('setShip',res.data);
          }
      })
      */
    

    },
    /**
     * 获取品牌
     * @param {*} context 
     */
    GetBrand(context){
      Api.good.GetBrands().then(res=>{
          if (res && res.code==0){
            context.commit('setBrand',res.data.authorizeBrand);
          }
      })
    },
    GetUser(context){
      Api.user.GetUserInfo().then(res=>{
        if (res && res.code==0){
          context.commit('setUser',res.data);
        }
      })
    },
    GetConfig(context){
      Api.common.GetConfigV2().then(res=>{
        if (res && res.code==0){
          context.commit('setConfig',res.data);
          
          window.document.title=res.data.shopName;
        }
      })
    }
  },
  modules: {
  }
})
