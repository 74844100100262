import axios from 'axios'
import getBaseUrl from './baseUrl'
import interceptors from './interceptors'
import qdk from '../src/common/qdk';
var qs = require('qs');




  //每次都要注入拦截器
  interceptors(axios);

class httpRequest {
   
  baseReqest(params, method = "GET") {
    let { url, data,param } = params;
    const BASE_URL = getBaseUrl();
    //const fullUrl=url.indexOf('http')==0? url :( BASE_URL + url);
 

    var contentType='application/json';
    var responseType='json';
    
    if (param){
        if ( 'contentType' in param){
          contentType=param.contentType;
        }
        if ( 'responseType' in param){
          responseType=param.responseType;
        }
    }
   
    const headers={  //请求头
      'content-type': contentType,
    };

    //token

    let tokenData=qdk.getStorageSync('Token');
    if (tokenData ){
          if (typeof (tokenData)=="string" && tokenData.indexOf('{')==0){
            tokenData =JSON.parse(tokenData);  
          }           
          if ('token' in tokenData){

            //检查是否过期
            if ( (tokenData.date + tokenData.expires_in*1000) > Date.now() ){
              
              headers['Authorization']=tokenData.token_type+" "+tokenData.token;
            }else{
              qdk.removeStorageSync("Token");
            }
            
            
          }   
    }
        
         
    
  
    //IE会缓存同URL的网络请求，会造成页面不稳定的现象，所以判断IE,在URL加上随机数
    let isie=window.ActiveXObject || "ActiveXObject" in window;
    if ( isie ){   
      url+=(url.indexOf('?')==-1?'?':'&')+'_='+Math.random();
    }


    

    


    const option = {
      baseURL:url.indexOf('http')==0?'':BASE_URL,
      url:url,  //地址
      data: data,   //传参    
      responseType, 
      method: method, //请求方式
      timeout:50000, // 超时时间
      // mode: 'no-cors',
      // withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'brackets'})
      },
      headers,     
    };

    if (method=="GET"){
      option.params=data;
    }else if (method=="POST" && contentType==ContentType.formType.contentType){
      option.data=qs.stringify(data);
    }

    return axios.request(option);
  }
  html(url,data=''){
    let param={dataType:'html'}
    let params = { url, data,param };
    return this.baseReqest(params, "GET");
  }
  get(url, data = "",param) {
    let option = { url, data,param };
    return this.baseReqest(option);
  }
 
  post(url, data,param) {
    let params = { url, data,param };
    return this.baseReqest(params, "POST");
  }
 
  put(url, data = "") {
    let option = { url, data };
    return this.baseReqest(option, "PUT");
  }
 
  delete(url, data = "") {
    let option = { url, data };
    return this.baseReqest(option, "DELETE");
  }


 
}
export default new httpRequest();

export const  ContentType={
  formType:{ contentType: 'application/x-www-form-urlencoded' },
  josnType:{ contentType: 'application/json'},
  fileType:{contentType:'multipart/form-data'},
} 