import HTTPREQUEST,{ContentType} from "../http";
export const payments = {

  /**
   * 获取支付方式列表
   */
  GetList(id) {
    return HTTPREQUEST.post("Payments/GetList");
  }, 

  


  
};
