export default {

    /**
     * 设置localStorage
     * @param {*} key 
     * @param {*} value 
     */
    setStorageSync(key,value){
      
        window.localStorage.setItem(key, JSON.stringify(value));
    },
    /**
     * 
     * @param {*} param0 {key,data ：object类型}
     */
    setStorage({key,data}){
       
        window.localStorage.setItem(key, JSON.stringify(data));
    },

    /**
     * 获取localStorage
     * @param {*} key 
     */
    getStorageSync(key){  
        
        let data= window.localStorage.getItem(key);
        if (!data || data=="undefined" || data=="null"){
            return '';
        }
        if (data)
            return JSON.parse(data);
        return data;
    },

    /**
     * 本地缓存中指定的 key
     * @param {*} key 
     */
    removeStorageSync(key){
       
        window.localStorage.removeItem(key);
    },



    /**
     * 获取sessionStorage
     * @param {*} key 
     */
    getSessionStorageSync(key){  
       
        let data= window.sessionStorage.getItem(key);
        if (!data || data=="undefined" || data=="null"){
            return '';
        }
        if (data)
            return JSON.parse(data);
        return data;
    },

     /**
     * 设置sessionStorage
     * @param {*} key 
     * @param {*} value 
     */
    setSessionStorageSync(key,value){
   
        window.sessionStorage.setItem(key, JSON.stringify(value));
    },
     /**
     * 设置sessionStorage
     * @param {*} param0 {key,data ：object类型}
     */
    setSessionStorage({key,data}){
       
        window.sessionStorage.setItem(key, JSON.stringify(data));
    },

    /**
     * 本地缓存中指定的 key
     * @param {*} key 
     */
    removeSessionStorage(key){
       
        window.sessionStorage.removeItem(key);
    },
}