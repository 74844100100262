
import {good} from './good'
import {user} from './user'
import {brand} from './brand'
import {order} from './order'
import {payments} from './payments'
import {cart} from './cart'
import {common} from './common'
export const Api = {
  good,
  user,
  brand,
  order,
  payments,
  cart,
  common,
}