import HTTPREQUEST from "../http"
// const formType=ContentType.formType;
export const cart={
  /**
   * 加入购物车
   * @param {*} nums 
   * @param {*} productId 
   * @param {*} type  1 替换 2 增加
   * @param {*} cartType 1 普通商品 2 拼团  。。。
   * @param {*} objectId 
   * @returns 
   */   
  AddCart(nums,productId,type,cartType,objectId){
    return HTTPREQUEST.post('/Cart/AddCart',{
      "nums": nums,
      "productId": productId,
      "type": type,
      "cartType": cartType,
      "objectId": objectId
    });
  },
  /**
   * 加入购物车
   * @param {*} nums 
   * @param {*} productId 
   * @param {*} type 
   * @param {*} cartType 
   * @param {*} objectId 
   * @returns 
   */
  AddCarts(data){
    return HTTPREQUEST.post('/Cart/AddCarts',data);
  },
  /**
   * 删除购物车
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  Delete(id,data){
    return HTTPREQUEST.post('/Cart/DoDelete',{
      "id": id,
      "data":data
    });
  },

  /**
   * 获取购物车
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  GetList(userId,ids,moneyType){
    return HTTPREQUEST.post('/Cart/GetList',{
      "userId": userId,
      "ids": ids,
      "moneyType":parseInt( moneyType),
      "type": 1,
      "areaId": 0,
      "point": 0,
      "couponCode": "",
      "receiptType": 0,
      "objectId": 0
    });
  },

  /**
   * 设置购物车数量
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  SetCartNum(id,nums){
    return HTTPREQUEST.post('/Cart/SetCartNum',{
      "id": id,
      "nums": nums
    });
  },
  SetCartRemark(id,row){
    return HTTPREQUEST.post('/Cart/SetCartInfo',{
      id,
      ...row
    });
  },
  Order2Cart(id){
      return HTTPREQUEST.post('/Cart/Order2Cart',{
      id,
      
    });
  },
}