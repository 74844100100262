const devHost={
    //web端本地
    localhst:'http://localhost:2015' ,
    
    demo:'https://api.demo.coreshop.cn',
    //服务器端
    remote:'http://order.yiwuxinshang.com',

    //server端本地
    remote_local:process.env.BASE_URL||'http://127.0.0.1'
}

/**
 * 获取基本目录
 * @param {*} url 
 * @param {*} Base_Dir 
 * @returns 
 */
const getBaseUrl = (Base_Dir) => {
    let BASE_URL = '';
    Base_Dir=typeof(Base_Dir)=='undefined'?"/api":Base_Dir;
   
    if (process.env.NODE_ENV === 'development') {
        
        //开发环境 - 根据请求不同返回不同的BASE_URL      
        //http://localhost:9001
        //http://192.168.0.122:9001
        BASE_URL =devHost.localhst +Base_Dir 
    } else {
        // 生产环境  
        
        //浏览器
        BASE_URL = devHost.remote +Base_Dir
         
       
       
     
    }
    return BASE_URL
  }
  
  export default getBaseUrl;