/**
 * 系统基本配置
 */
export const config = {
    cdn:"http://static.yiwuxinshang.com",
    cdnDomain:"static.yiwuxinshang.com",
    siteName:"新尚下单管理系统",
    siteNick:"新尚",
    loginUrl:"#/login",
}

