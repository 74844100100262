import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
 
 
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/ShopView.vue')
  },
  {
    path: '/shopd5',
    name: 'shopd5',
    component: () => import('../views/ShopD5View.vue')
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('../views/mineView.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/orderView.vue')
  },
  {
    path: '/orderdetail',
    name: 'orderdetail',
    component: () => import('../views/orderDetailView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta:{
      full:1,
    },
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/findPassWd',
    name: 'findPassWd',
    meta:{
      full:1,
    },
    component: () => import('../views/findPassWdView.vue')
  },
  {
    path: '/editPassWd',
    name: 'editPassWd',
    component: () => import('../views/editPassWdView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/logoutView.vue')
  }
]

const originaPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originaPush.call(this, location).catch(err => err)
}
 
const router = new VueRouter({
  routes,
  // 添加下面的选项来取消 NavigationDuplicated 错误的抛出
  duplicateNavigationPolicy: 'ignore',

})

export default router
