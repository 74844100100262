<template>
  <div id="app">
    
    <router-view v-if="full" />
    
    
    <el-container v-else  >
      <el-header style="text-align: right; font-size: 12px">
        <topNav></topNav>
      </el-header>
      <el-container>
        <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
           
          <sider></sider>
        </el-aside>
     
        <el-container>
          <el-main>

            <router-view/>

          </el-main>
        </el-container>
      </el-container>
    </el-container>
    
  </div>
</template>


<script>
import topNav from './components/topNavComp.vue'
import sider from './components/SiderComp.vue';
import page  from './common/page';
  export default {
    components:{
      sider,topNav
    },
    computed: {
      currentRoute() {
        return this.$route
      },
      full(){
        return this.$route.meta.full
      }
    },
    watch: {
      //监听路由变化
      $route:{
        deep: true,
        handler(){
          console.log('路由变化了');
          console.log(this.$route);
        }
      }
    },
    mounted () {
      setTimeout(() => {
        page.initPage(this);
      },1000);
    },

  }
</script>

<style lang="scss">
 
</style>
