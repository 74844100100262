import HTTPREQUEST from "../http"
// const formType=ContentType.formType;
export const user={
   
  /**
   * 登录
   * @param {*} userName 
   * @param {*} password 
   * @returns 
   */
  Login(userName,password){
    return HTTPREQUEST.post('/User/Login',{userName,password});
  },

  /**
   * 注销登录
   */
  Logout(){
    return HTTPREQUEST.post('/User/Logout');
  },

  /**
   * 获取购物车商品数量 (Auth)
   * @returns 
   */
  GetCartNumberer(){
    return HTTPREQUEST.post('/User/GetCartNumberer');
  },

  /**
   * 获取用户信息 (Auth)
   * @returns 
   */
  GetUserInfo(){
    return HTTPREQUEST.post('/User/GetUserInfo');
  },

  /**
   * 获取购物车商品数量 (Auth)
   * @param {*} data {"birthday": "2024-01-24T01:51:19.199Z", "nickname": "string", "sex": 0}
   * @returns 
   */
  EditInfo(data){
    return HTTPREQUEST.post('/User/EditInfo',data);
  },

  /**
   * 修改密码
   * @param {*} data  { "newpwd": "string",  "repwd": "string",  "pwd": "string"}
   * @returns 
   */
  EditPwd(data){
    return HTTPREQUEST.post('/User/EditPwd',data);
  },

   /**
   * 找回密码
   * @param {*} data  {
  "mobile": "string",
  "code": "string",
  "newpwd": "string",
  "repwd": "string"
}
   * @returns 
   */
  ForgetPwd(data){
    return HTTPREQUEST.post('/User/ForgetPwd',data);
  },  
  /**
   * 发送忘记密码的邮件
   * @param {*} data  {
   * code:forgot,
   * email:""
   * }
   * @returns 
   */
  SendForgotMail(data){
    return HTTPREQUEST.post('/User/SendForgotMail',data);
  },
  
}