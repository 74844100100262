import { HTTP_STATUS } from './config';
//import {i18n} from '../src/plugins/i18n'
import Vue from 'vue';
import { config } from '../src/common/config';
import qdk from '../src/common/qdk';
const customInterceptor = (axios) => {


  
  //response 拦截器
  axios.interceptors.response.use(
    response => {  // 状态代码为【2xx】时将会触发此函数]    
      
     
      
      let res;
      if (response && typeof response=='object'){
        if (  'data' in response && 'status' in response && 'statusText' in response)
          res= response.data;
        else 
          res= response;


        //根据返回数据，自动翻译错误信息
        if ( !res.status && res.msg){
       
          //res.msg=  Vue.prototype.$t(res.msg);
        }
        if ( !res.status && ( res.code==14007 ||res.code==14006) ){
          //登录过期
          qdk.removeStorageSync('Token','');
          if (config.loginUrl!=window.location.href)
            window.location.href= config.loginUrl;
        }

        return res;
      }
      return response;
     
    }, 
    error => {  // 状态代码超出【2xx】范围时将会触发此函数，列如：4xx、5xx、...
      if (error.response) {
           
          switch (error.response.status) {
            case HTTP_STATUS.NOT_FOUND:
              console.log("请求资源不存在");
              break;
            case HTTP_STATUS.BAD_GATEWAY:
              console.log("服务端出现了问题");
              break;
            case HTTP_STATUS.FORBIDDEN:
              console.log("没有权限访问");
              break;
            case HTTP_STATUS.AUTHENTICATE:
              console.log("需要鉴权");
              break;
            case HTTP_STATUS.SERVER_ERROR:
              console.log("服务器错误");
              break;
            case HTTP_STATUS.SUCCESS:
              
              break;
        }
      }
  })

   
}




export default customInterceptor