<template>
    <div class="py-4 pr-4 flex justify-between"  >
        <!--  style="background: #e3e3e3;" -->
       
        <div class="flex">
            <img style="height:40px;"  class="ml-8"  src="../assets/logo.png"/>
            <img style="height:40px;"  class="ml-8"   src="../assets/logo-text.png"/>
        </div>
        <div>
            <span class="pr-4" v-if="islogin">你好，{{ userName }} ,</span>
            <nav class=" inline mr-4">
            <router-link class="mr-1" to="/shop">{{$t('onlineShop')}}</router-link> |
            <router-link class="mx-1" to="/order">{{$t('myOrder')}}</router-link> |
            <router-link class="mx-1" to="/mine">{{$t('profile')}}</router-link> |
            <router-link class="mx-1" to="/editPassWd">{{$t('modifyPwd')}}</router-link> |
                <template v-if="!islogin">
                    <router-link  class="mx-1" to="/login">{{$t('login')}}</router-link>
                </template>
            
                <template v-if="islogin">
                    <router-link  class="mx-1" v-if="islogin" to="/logout">{{$t('logout')}}</router-link>
                </template>
            
            </nav>

            <el-select :size="'mini'" v-model="$i18n.locale" @change="langChange">
                <el-option v-for="(lang, i) in $store.state.langs"  
                :value="lang.value"
                :label="lang.label"></el-option>
            
            </el-select>

        </div>
    
    </div>
</template>

<script>
import Vue from 'vue';
import qdk from '../common/qdk';
    export default {
        name:"topNavComp",
        data() {


            return {
               
            }
        },
        computed: {
            islogin() {
                let tokenData=qdk.getStorageSync('Token');
                if (tokenData ){
                    if (typeof (tokenData)=="string" && tokenData.indexOf('{')==0){
                        tokenData =JSON.parse(tokenData);  
                    }           
                    if ('token' in tokenData){

                        //检查是否过期
                        if ( (tokenData.date + tokenData.expires_in*1000) < Date.now() ){
                            
                        }else{
                            return true;
                        }
                        
                        
                    }   
                }
                return false;
            },
            userName(){
                let user=this.$store.state.user;
                if (user){
                    return user.userName
                }else{
                    return "";
                }
            }
            
        },
        methods: {
            langChange(){         
                qdk.setStorageSync("lang",this.$i18n.locale);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>